import React from 'react';
import { useGetRouletteLineIds } from 'games/CaseGame/hooks';
import {
  ButtonContainer,
  IconsContainer,
  Line,
  RouletteContainer,
  Wrapper,
} from './Roulette.styles';
import { SvgIcons } from 'games/CaseGame/assets';
import {
  ButtonDecorator,
  ButtonOpen,
  DemoOpen,
  FreeOpen,
  RouletteBuilder,
  RouletteCountButton,
} from './components';
import { generateId } from 'games/CaseGame/utils';
import { CaseEnum } from 'games/CaseGame/constants';
// import { CaseOpenButton, EventProgress } from 'modules/event-module/dragon-event/components';
// import { TicketTypeEnum } from 'modules/event-module/dragon-event';
import { isEqual } from 'lodash';

interface RouletteProps {
  caseId: string;
  search?: string;
  isFreeFieldExist: boolean;
  categoryId: number;
  caseType: CaseEnum;
  //NOTE: Added for event-summertime
  // ticketData: {
  //   ticketsAmount: number;
  //   ticketColor: TicketTypeEnum;
  // } | null;
}
export enum LineType {
  UP = 'up',
  DOWN = 'down',
}

export const Roulette: React.FC<RouletteProps> = React.memo(
  ({ caseId, search, isFreeFieldExist, caseType, categoryId }) => {
    const { isLoading: isLoadingRoulette, rouletteLineIds } = useGetRouletteLineIds();
    const size = window.innerWidth / 2;
    //NOTE: event logic
    // const eventLine =
    //   rouletteLineIds?.length === 1
    //     ? 0
    //     : Math.abs(Math.floor(Math.random() * rouletteLineIds?.length) - 1);

    return (
      <div>
        <RouletteContainer>
          <IconsContainer>
            <SvgIcons.RouletteArrowDown />
            <Line data-type={LineType.DOWN} />
          </IconsContainer>
          <Wrapper>
            {!isLoadingRoulette &&
              rouletteLineIds?.map((idArray, index, array) => (
                <RouletteBuilder
                  key={generateId(10)}
                  size={size}
                  ids={idArray}
                  // isNeedToShowEventBackground={eventLine === index}
                  lineCount={array.length}
                  lineIndex={index}
                />
              ))}
          </Wrapper>
          <IconsContainer>
            <Line data-type={LineType.UP} />
            <SvgIcons.RouletteArrowUp />
          </IconsContainer>
        </RouletteContainer>

        <ButtonContainer isFreeFieldExist={isFreeFieldExist}>
          {search ? (
            <ButtonOpen caseId={caseId} lineCount={rouletteLineIds?.length} />
          ) : isFreeFieldExist || caseType === CaseEnum.FREE ? (
            <FreeOpen caseId={caseId} lineCount={rouletteLineIds?.length} categoryId={categoryId} />
          ) : (
            <>
              {' '}
              <RouletteCountButton caseId={caseId} />
              <ButtonDecorator lineCount={rouletteLineIds?.length} />
              <ButtonOpen caseId={caseId} lineCount={rouletteLineIds?.length} />
              <DemoOpen caseId={caseId} lineCount={rouletteLineIds?.length} />
            </>
          )}
        </ButtonContainer>
        {/* <EventProgress categoryId={categoryId} /> */}
      </div>
    );
  },
  isEqual
);
