import dragonsMenuPath from './event-logo.webp';
import balanceBannerPath from './balance-banner.webp';
import balanceBannerPath2 from './balance-banner-2.webp';
import prizePath from './prize.png';
import topBgPath from './top-bg.png';
import superPricePath from './super-prize.webp';
import widgetEggPath from './widget-egg.webp';
import slider1Path from './slider1.png';
import mainBannerPath from './main-banner.webp';
import winnerPath from './winner.webp';
import tabBgPath from './tab-bg.svg';

import { ReactComponent as CoinDaily } from './coin-daily.svg';
import { ReactComponent as CoinWeekly } from './coin-weekly.svg';
import { ReactComponent as CoinSpecial } from './coin-special.svg';

import { ReactComponent as Slide2 } from './slide2.svg';
import { ReactComponent as Slide3 } from './slide3.svg';
import { ReactComponent as Replay } from './replay.svg';
import { ReactComponent as Lock } from './lock.svg';
import { ReactComponent as Special } from './special.svg';

import { ReactComponent as HeaderSignboard } from './header-signboard.svg';

import { ReactComponent as ButtonLeft } from './button-left.svg';
import { ReactComponent as ButtonRight } from './button-right.svg';
import { ReactComponent as MotivatorBg } from './motivator-bg.svg';
import { ReactComponent as Box } from './box.svg';
import { ReactComponent as Completed } from './completed.svg';
import { ReactComponent as FirstPlace } from './first-place.svg';
import { ReactComponent as SecondPlace } from './second-place.svg';
import { ReactComponent as ThirdPlace } from './third-place.svg';
import { ReactComponent as Next } from './next.svg';
import { ReactComponent as AppBarEventIcon } from './app-bar.svg';
import { ReactComponent as CoinDailyWidget } from './coin-daily-widget.svg';

import loaderDragonEvent from './loader.gif';

export const eventImagesPath = {
  dragonsMenuPath,
  balanceBannerPath2,
  balanceBannerPath,
  prizePath,
  topBgPath,
  superPricePath,
  widgetEggPath,
  slider1Path,
  mainBannerPath,
  winnerPath,
  tabBgPath,
};

export const EventIcons = {
  CoinDailyWidget,
  CoinDaily,
  CoinWeekly,
  CoinSpecial,
  AppBarEventIcon,
  Slide2,
  Slide3,
  Replay,
  Lock,
  Special,
  HeaderSignboard,
  ButtonLeft,
  ButtonRight,
  MotivatorBg,
  Box,
  Completed,
  FirstPlace,
  SecondPlace,
  ThirdPlace,
  Next,
};

export const loader = {
  loaderDragonEvent,
};
