import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, ActionBlock } from './BalanceActions.styles';

export const BalanceActions = () => {
  const { t } = useTranslation();
  return (
    <>
      {/* <EventLink to={Modules.EVENT} as={Link}>
        <div>
          <span>Dragons 2024</span>
        </div>
      </EventLink> */}
      <Container>
        <ActionBlock to="/withdrawal/method">
          <i className="icon-minus-circle" />
          <span>{t('Withdraw')}</span>
        </ActionBlock>
        <ActionBlock to="/refill">
          <i className="icon-plus-circle" />
          <span>{t('Refill')}</span>
        </ActionBlock>
      </Container>
    </>
  );
};
