import { Reducer } from 'react';
import { of } from 'rxjs';
import { ActionsObservable, Epic, ofType } from 'redux-observable';
import { catchError, map, pluck, switchMap } from 'rxjs/operators';
import { SUMMERTIME_EVENT_ACTIONS_TYPES } from '../actionTypes';
import {
  ActionPickUpPrize,
  ActionRequestEventCategory,
  ActionRequestMotivatorData,
  ActionRequestQuestInfo,
  EventCategoryActions,
  EventCategoryState,
  GeneralGuestInfoState,
  GeneralInfoActions,
  MotivatorEvent,
  MotivatorEventActions,
  MotivatorReducerState,
  MotivatorResponse,
  RareCaseActions,
  RareCaseInfoState,
} from './default-event.interfaces';
import eventRepository from '../../repository/event.repository';
import { addNotification } from 'modules/Notifications/duck';
import {
  CaseLuckyWonEvent,
  CaseQuestMotivatorLast,
  CaseQuestProgress,
  CasesQuestStatusEnum,
  GeneralQuestInfoResponse,
} from '../../interfaces';
import { IDispatch, IEventsTypes } from 'core/rootInterfaces/root.interface';
import { CategoryNotDetailed } from 'games/CaseGame';
import { AsyncAction } from 'store/interface';

export const actionRequestQuestInfo = () => ({
  type: SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_REQUEST_EVENT_DATA,
});

const actionResponseQuestInfo = (data: GeneralQuestInfoResponse) => ({
  type: SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_RESPONSE_EVENT_DATA,
  payload: data,
});

export const eventDataEpic = (action$: ActionsObservable<ActionRequestQuestInfo>) =>
  action$.pipe(
    ofType(SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_REQUEST_EVENT_DATA),
    switchMap(() =>
      eventRepository.getQuestInfo().pipe(
        map(({ response }: { response: GeneralQuestInfoResponse }) =>
          actionResponseQuestInfo(response)
        ),
        catchError(() =>
          of(
            addNotification({
              type: 'error',
              body: 'Something went wrong. Please reload the page!',
            })
          )
        )
      )
    )
  );

const initState: GeneralGuestInfoState = {
  isLoading: true,
  isLoaded: false,
  data: null,
};

export const eventGeneralQuestInfoReducer: Reducer<GeneralGuestInfoState, GeneralInfoActions> = (
  state = initState,
  action
) => {
  switch (action.type) {
    case SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_REQUEST_EVENT_DATA: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_RESPONSE_EVENT_DATA: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: action.payload,
      };
    }
    case SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_RESPONSE_PICK_UP_PRIZE: {
      if (state.data) {
        const updateCategories = state.data.categories.map(category => {
          if (category.entityId === action.payload.entityId) {
            return {
              ...category,
              status: CasesQuestStatusEnum.TAKEN,
            };
          }
          return category;
        });

        return {
          ...state,
          data: { ...state.data, categories: updateCategories },
        };
      }
      return state;
    }
    case SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_PICK_UP_PRIZE_OPENED: {
      if (state.data) {
        const updateCategories = state.data.categories.map(category => {
          if (category.entityId === action.payload.entityId) {
            return {
              ...category,
              status: CasesQuestStatusEnum.OPENED,
            };
          }
          return category;
        });

        return {
          ...state,
          data: { ...state.data, categories: updateCategories },
        };
      }
      return state;
    }
    case SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_EVENT_DATA_FROM_SOCKET_EVENT: {
      if (state.data) {
        const updateCategories = state.data.categories.map(category => {
          if (category.entityId === action.payload.entityId) {
            return {
              ...category,
              progress: action.payload.progress,
              status: action.payload.quest?.rewardAvailable
                ? CasesQuestStatusEnum.AVAILABLE
                : category.status,
            };
          }
          return category;
        });

        return {
          ...state,
          data: {
            ...state.data,
            categories: updateCategories,
          },
        };
      }
      return state;
    }
    default: {
      return state;
    }
  }
};

export const actionRequestMotivatorData = () => ({
  type: SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_REQUEST_MOTIVATOR_DATA,
});

export const actionMotivatorSocketEvent = (data: MotivatorEvent<CaseQuestMotivatorLast>) => ({
  type: SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_RESPONSE_MOTIVATOR_EVENT,
  payload: data,
});

const actionResponseMotivatorData = (data: MotivatorEvent<CaseQuestMotivatorLast>[]) => ({
  type: SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_RESPONSE_MOTIVATOR_DATA,
  payload: data,
});

export const motivatorEventEpic: Epic = (action$: ActionsObservable<ActionRequestMotivatorData>) =>
  action$.pipe(
    ofType(SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_REQUEST_MOTIVATOR_DATA),
    switchMap(() =>
      eventRepository.getMotivatorList().pipe(
        map(({ response }: { response: MotivatorResponse }) =>
          actionResponseMotivatorData(response.last)
        ),
        catchError(() =>
          of(
            addNotification({
              type: 'error',
              body: 'Something went wrong. Please reload the page!',
            })
          )
        )
      )
    )
  );

const initData: MotivatorReducerState = {
  data: [],
  isLoading: false,
};

export const motivatorEventReducer: Reducer<MotivatorReducerState, MotivatorEventActions> = (
  state = initData,
  action
) => {
  switch (action.type) {
    case SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_REQUEST_MOTIVATOR_DATA: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_RESPONSE_MOTIVATOR_DATA: {
      return {
        ...state,
        data: action.payload.map(({ data }) => data),
        isLoading: false,
      };
    }
    case SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_RESPONSE_MOTIVATOR_EVENT: {
      if (action.payload.agentKey === 'cases-quest') {
        return {
          ...state,
          data: [action.payload.data].concat(state.data.slice(0, -1)),
        };
      }

      return state;
    }
    default: {
      return state;
    }
  }
};

const actionEventDataFromSocketEvent = (data: CaseQuestProgress): AsyncAction => (
  dispatch: IDispatch
) => {
  if (data.quest.rewardAvailable) {
    dispatch(actionRequestQuestInfo());
  }
  dispatch({
    type: SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_EVENT_DATA_FROM_SOCKET_EVENT,
    payload: data,
  });
};

const actionResponseCaseRareInfoWon = (data: CaseLuckyWonEvent) => ({
  type: SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_RESPONSE_CASE_RARE_INFO,
  payload: data,
});

const actionResponseSocketRareCaseInfo = (data: CaseLuckyWonEvent): AsyncAction => (
  dispatch: IDispatch
) => {
  dispatch(actionResponseCaseRareInfoWon(data));
};

const actionResponsePickUpPrize = (entityId: number, targetId: number) => ({
  type: SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_RESPONSE_PICK_UP_PRIZE,
  payload: {
    entityId,
    targetId,
  },
});

export const actionPickUpPrizeOpened = (entityId: number) => ({
  type: SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_PICK_UP_PRIZE_OPENED,
  payload: {
    entityId,
  },
});

export const actionPickUpPrize = ({
  entityId,
  questId,
  redirectHandler,
  caseId,
}: {
  entityId: number;
  questId?: number;
  redirectHandler: () => void;
  caseId?: number;
}) => ({
  type: SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_PICK_UP_PRIZE,
  payload: {
    entityId,
    questId,
    caseId,
    redirectHandler,
  },
});

export const pickUpPrize: Epic = (action$: ActionsObservable<ActionPickUpPrize>) =>
  action$.pipe(
    ofType(SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_PICK_UP_PRIZE),
    pluck('payload'),
    switchMap(({ entityId, questId, redirectHandler }) =>
      eventRepository.pickUpPrize(entityId).pipe(
        switchMap(() => of(actionResponsePickUpPrize(entityId, questId))),
        map(() => {
          return {
            type: 'just for redirect type',
            payload: redirectHandler(),
          };
        }),
        catchError(() =>
          of(
            addNotification({
              type: 'error',
              body: 'Something went wrong. Please reload the page!',
            })
          )
        )
      )
    )
  );

export const actionRequestEventCategory = () => ({
  type: SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_REQUEST_CATEGORY,
});

const actionResponseEventCategory = (data: CategoryNotDetailed[]) => ({
  type: SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_RESPONSE_CATEGORY,
  payload: data,
});

const initStateEventCategory: EventCategoryState = {
  isLoading: true,
  data: [],
};

export const eventCategoryReducer: Reducer<EventCategoryState, EventCategoryActions> = (
  state = initStateEventCategory,
  action
) => {
  switch (action.type) {
    case SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_RESPONSE_CATEGORY: {
      return {
        data: action.payload,
        isLoading: false,
      };
    }
    case SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_REQUEST_CATEGORY: {
      return {
        ...state,
        isLoading: true,
      };
    }
    default: {
      return state;
    }
  }
};

export const eventCategoryEpic: Epic = (action$: ActionsObservable<ActionRequestEventCategory>) =>
  action$.pipe(
    ofType(SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_REQUEST_CATEGORY),
    switchMap(() =>
      eventRepository.getCategoryEvent().pipe(
        map(({ response }: { response: CategoryNotDetailed[] }) =>
          actionResponseEventCategory(response)
        ),
        catchError(() =>
          of(
            addNotification({
              type: 'error',
              body: 'Something went wrong. Please reload the page!',
            })
          )
        )
      )
    )
  );

export const actionClearRareCaseInfo = () => ({
  type: SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_CLEAR_RARE_CASE_INFO,
});

export const rareCaseInfoReducer: Reducer<RareCaseInfoState | null, RareCaseActions> = (
  state = null,
  action
) => {
  switch (action.type) {
    case SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_CLEAR_RARE_CASE_INFO: {
      return null;
    }
    case SUMMERTIME_EVENT_ACTIONS_TYPES.ACTION_RESPONSE_CASE_RARE_INFO: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

export const eventsTypes: IEventsTypes[] = [
  {
    action: actionResponseSocketRareCaseInfo,
    /** выпал бонусный(супер редкий) кейс*/
    event: 'cases-quest:lucky-won',
  },
  {
    action: actionEventDataFromSocketEvent,
    /** летит после открытия кейса*/
    event: 'cases-quest:progress',
  },
];
