import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 90.39px 19px 39px;
  width: 100%;
`;

export const Icons = styled.div`
  height: 110px;
  margin: 19px 0 40px;
  position: relative;
  width: 163px;

  & > svg {
    height: 80px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 80px;

    &:first-child {
      left: 27%;
      top: 40px;
      z-index: 10;
    }

    &:last-child {
      left: 60%;
      top: 53px;
      z-index: 20;
    }
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const Title = styled.span`
  color: var(--color-white);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
`;

export const Rules = styled.div`
  color: var(--color-white);
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;

  & > span.accent-first {
    color: #599aff;
  }

  & > span.accent-second {
    color: #fac106;
  }

  & > span.accent-third {
    color: #fa8200;
  }
`;
