import styled from 'styled-components';
import { URLS } from 'services/constant-urls';
// import { eventImagesPath } from 'modules/event-module/dragon-event/assets';
// background-image: url(${eventImagesPath.balanceBannerPath2});
export const Container = styled.div`
  align-content: center;
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  grid-auto-rows: min-content;
  grid-gap: 0.3125rem;
  min-height: 12rem;
  position: relative;

  &::before,
  &::after {
    /* background: url(<event-image>) no-repeat; */
    background-size: 100%;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    width: 126px;
  }

  &::before {
    left: 0;
  }

  &::after {
    right: -10px;
    transform: scale(-1, 1);
  }

  & div {
    justify-self: center;
  }

  & > .balance {
    backdrop-filter: blur(5px);
    background: rgba(0, 0, 0, 0.2);
    border-radius: var(--size-radius-l);
    color: var(--color-white);
    font-size: 1.75rem;
    line-height: 2rem;
    padding: 16px;
  }

  & > .cashback {
    font-size: 0.8125rem;
    line-height: 1rem;
    letter-spacing: 0.1219px;
    color: var(--color-white);
  }

  &.bonus-event {
    &::after,
    &::before {
      content: '';
      display: block;
      position: absolute;
    }

    &::before {
      top: 0;
      left: 0;
      background: radial-gradient(
        60.3% 60.3% at 50% 39.7%,
        var(--color-green-200) 0%,
        var(--color-green-300) 100%
      );
      width: 100%;
      height: 100%;
    }

    &::after {
      width: 80px;
      height: 80px;
      right: -30px;
      background: url(${URLS.refill.bonus}) no-repeat;
      background-size: cover;
    }
  }
`;

// const CustomSelect = styled.div`
//   display: flex;
//   flex-flow: row;
//   justify-content: center;
//   align-items: center;
//   font-size: 1.8rem;
//   color: var(--light);
//   position: relative;
//   padding-right: 1rem;

//   & > select {
//     min-width: 100%;
//     min-height: 25px;
//     padding: 0;
//     position: absolute;
//     margin: 0;
//     color: var(--light);
//     font-size: 0;
//     text-align: center;
//     background: transparent;
//     border: 0;
//     font-family: 'Roboto';
//     -webkit-appearance: none;
//     outline: none;
//   }

//   & > span {
//     position: relative;
//     left: 0;
//   }

//   & > div {
//     width: 10px;
//     height: 10px;
//     background: var(--light);
//     clip-path: polygon(50% 100%, 0 50%, 100% 50%);
//     position: absolute;
//     top: 50%;
//     margin-top: -8px;
//     right: 0;
//   }
// `
