import React, { useEffect } from 'react';
import {
  Balance,
  BalanceContainer,
  CloseButton,
  CloseContainer,
  Container,
  GridContainer,
  HeaderContainer,
  Title,
} from './case-shop.styles';
import { IDialogComponent } from 'core/interfaces';
import { useTranslation } from 'react-i18next';
import { useGetCasesLr, useGetLRBalance } from '../../hooks';
import { EventIcons } from '../../assets';
import { CaseItem, ItemContainer } from '../../components/leader-race/case-item';
import { useDispatch } from 'react-redux';
import { actionRequestLeaderRaceCases } from '../../duck/leader-race-duck';

export interface CaseShopProps extends IDialogComponent<boolean> {}

export const CaseShop: React.FC<CaseShopProps> = ({ handleCloseModal }) => {
  const { t } = useTranslation();
  const { balanceData } = useGetLRBalance();
  const { isLoading, data } = useGetCasesLr();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionRequestLeaderRaceCases());
  }, [dispatch]);

  const arrayBalance = [
    {
      icon: <EventIcons.CoinDaily width={16} height={16} />,
      balance: balanceData.daily,
    },
    {
      icon: <EventIcons.CoinWeekly width={16} height={16} />,
      balance: balanceData.weekly,
    },
    {
      icon: <EventIcons.CoinSpecial width={16} height={16} />,
      balance: balanceData.special,
    },
  ];

  return (
    <Container>
      <HeaderContainer>
        <Title>{t('yourCoins')}</Title>
        <BalanceContainer>
          {arrayBalance.map(({ icon, balance }, index) => (
            <Balance key={index}>
              {icon}
              <span>{balance}</span>
            </Balance>
          ))}
        </BalanceContainer>
      </HeaderContainer>
      <GridContainer>
        {isLoading
          ? [1, 2, 3, 4, 5, 6].map(item => <ItemContainer key={item} className="skeleton" />)
          : data.map(value => (
              <CaseItem
                id={value.id}
                key={value.id}
                price={value.ticketQuest.ticketsAmount}
                isFree={!!value?.free}
                image={value?.image}
                fruitType={value.ticketQuest.ticketColor}
                backgroundImage={value?.backgroundImage}
              />
            ))}
      </GridContainer>
      <CloseContainer>
        <CloseButton onClick={handleCloseModal}>{t('close')}</CloseButton>
      </CloseContainer>
    </Container>
  );
};
