import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Container, Icons, Description, Title, Rules } from './stage4.styles';
import { StageHeader } from '../stage-header';
import { EventIcons } from '../../../assets';

export const Stage4 = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <StageHeader />
      <Icons>
        <EventIcons.CoinWeekly />
        <EventIcons.CoinDaily />
        <EventIcons.CoinSpecial />
      </Icons>
      <Description>
        <Title>{t('whatCoins')}</Title>
        <Rules>
          {' '}
          <Trans i18nKey="whatCoinsDescription" t={t}>
            Coins make it possible to open bonus cases and get cool rewards in them! Like quests,
            coins are divided into three types: <span className="accent-third">copper</span>,
            <span className="accent-second">silver</span> and
            <span className="accent-first">golder</span>. Each type of coins gives access to opening
            unique summer cases!
          </Trans>
        </Rules>
      </Description>
    </Container>
  );
};

//
