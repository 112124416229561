import { IStore } from 'store/interface';

export const getGeneralQuestInfo = ({ eventModule }: IStore) =>
  eventModule.dragonEventReducer.eventGeneralQuestInfoReducer;

export const getMotivatorData = ({ eventModule }: IStore) =>
  eventModule.dragonEventReducer.motivatorEventReducer;

export const getCategoryEvent = ({ eventModule }: IStore) =>
  eventModule.dragonEventReducer.eventCategoryReducer;

export const getLuckyWonData = ({ eventModule }: IStore) =>
  eventModule.dragonEventReducer.rareCaseInfoReducer;

export const getLRBalance = ({ eventModule }: IStore) =>
  eventModule.dragonEventReducer.leaderRaceBalanceReducer;

export const getLRQuests = ({ eventModule }: IStore) =>
  eventModule.dragonEventReducer.leaderRaceQuestsReducer;

export const getCasesLR = ({ eventModule }: IStore) =>
  eventModule.dragonEventReducer.casesLRReducer;

export const getLRInfo = ({ eventModule }: IStore) =>
  eventModule.dragonEventReducer.leaderRaceInfoReducer;
