import { SkeletonElement } from '@UIkit';
import styled from 'styled-components';

export const ItemContainer = styled(SkeletonElement)`
  align-items: center;
  aspect-ratio: 1/1.0917;
  background: var(--color-dark-200);
  border-radius: var(--size-radius-m);
  display: flex;
  justify-content: center;
  position: relative;
  text-align: center;
  width: 100%;
`;

export const CoinContainer = styled.div`
  align-items: center;
  background-color: #121719;
  border-radius: var(--size-radius-m);
  bottom: 12px;
  display: flex;
  gap: 4px;
  justify-content: center;
  left: 50%;
  padding: 4px 8px;
  position: absolute;
  transform: translateX(-50%);
`;

export const CaseIcon = styled.img`
  border-radius: var(--size-radius-m);
  height: 100%;
  width: 100%;
`;

export const CasePrice = styled.span`
  color: var(--color-gray-200);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 14px;
  text-align: center;
`;

export const CaseIconFree = styled.img`
  border-radius: var(--size-radius-m);
  max-height: 100%;
  max-width: 100%;
`;
